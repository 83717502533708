import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="charts"
export default class extends Controller {
  static targets = [ "revenueSummary", "citationActivity", "citationSummary", "tiretagSummary" ]
  static values = {
    stats: Object,
  }

  revenueSummaryTargetConnected(){
    const statsDuration = this.statsValue.duration
    const xlabel = this.determineGraphXLabel(statsDuration)
    const graphType = (xlabel == "Days") ? "line" : "bar"
    var data = this.statsValue.duration_revenue
    var colors = {"backgroundColor": "rgb(255, 99, 132)", "borderColor": "rgb(255, 99, 132)"};
    this.initLineChart(this.revenueSummaryTarget, data, colors, graphType, xlabel, "Revenue ($)", 100)
  }
  
  citationActivityTargetConnected(){
    const statsDuration = this.statsValue.duration
    const xlabel = this.determineGraphXLabel(statsDuration)
    const graphType = (xlabel == "Days") ? "line" : "bar"
    var data = this.statsValue.duration_citations
    var colors = {"backgroundColor": "", "borderColor": "rgb(0, 0, 255)"};
    this.initLineChart(this.citationActivityTarget, data, colors, graphType, xlabel, "Citations", 1)
  }

  citationSummaryTargetConnected(){
    var data = this.statsValue.duration_citations_totals
    var colors = ["green", "orange", "red", "purple", "grey"]
    this.initPieChart(this.citationSummaryTarget, data, colors, "Citations Summary")
  }

  tiretagSummaryTargetConnected(){
    var data = this.statsValue.tiretags
    var colors = ["blue", "green", "yellow"]
    this.initPieChart(this.tiretagSummaryTarget, data, colors, "Tire Tags Summary")
  }

  initLineChart(target, data, colors, graphType, xlabel, ylabel, stepSize) {
    let chart = new Chart(target.getContext("2d"), {
      // The type of chart we want to create
      type: graphType,

      // The data for our dataset
      data: {
        labels: Object.keys(data),
        datasets: [{
          backgroundColor: colors["backgroundColor"],
          borderColor: colors["borderColor"],
          data: Object.values(data),
        }]
      },

      // Configuration options go here
      options: {
        title: {
          display: false
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            ticks: { min: 0, stepSize: stepSize },
            scaleLabel: {
              display: true,
              labelString: ylabel,
            },
          }],
          xAxes: [{
              scaleLabel: {
                display: true,
                labelString: xlabel,
              },
          }]
        },
        tooltips: {
          enabled: true,
          callbacks: {
            label: function(tooltipItem, chartData) {
              let value = chartData.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
              if (ylabel === "Revenue ($)") {
                return `$${value}`;
              }
              return value;
            }
          }
        }
      }
    });
  }

  initPieChart(target, data, colors, label) {
    const labels = Object.keys(data).map(element => 
      {
        return element.split('_')
                      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                      .join(' ');
      });
    let chart = new Chart(target.getContext("2d"), {
      type: 'pie',
      data: {
        labels: labels,
        datasets: [{
          backgroundColor: colors,
          data: Object.values(data),
        }]
      },
      options: {
        responsive: true,
        title: {
          display: true,
          text: label
        },
        legend: {
          display: true,
          position: "right"
        }
      }
    });
  }

  determineGraphXLabel(statsDuration) {
    const durationMapping = {
      year: "Months",
      quarter: "Months",
      all_time: "Years",
    };
    return durationMapping[statsDuration] || "Days";
  }
}
