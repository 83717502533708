import { Controller } from "@hotwired/stimulus";
import swal from "sweetalert2";

// Connects to data-controller="sweet-alert"
export default class extends Controller {
  static targets = ["swalError", "swalSuccess"];
  static values = {
    errors: String,
    success: String,
    redirectBack: Boolean,
    title: String,
  };

  swalErrorTargetConnected() {
    swal.fire("", this.errorsValue, "error");
  }

  swalSuccessTargetConnected() {
    if (this.redirectBackValue){
      swal.fire({
        title: this.titleValue || "Success",
        html: this.successValue,
        icon: "success",
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then((result) => {
        if (result) window.history.back()
      });

    }else{
      swal.fire("Success", this.successValue, "success");
    }
  }

  confirm(event) {
    event.preventDefault();
    const originalTarget = event.currentTarget;
    const message = originalTarget.getAttribute("data-turbo-confirm");

    swal.fire({
      title: "Are you sure?",
      text: message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        this.performAction(originalTarget);
      }
    });
  }

  performAction(target) {
    const form = target.closest("form");
    if (form) {
      form.submit();
    } else {
      const href = target.getAttribute("href");
      const method = target.getAttribute("data-turbo-method") || "get";
      Turbo.visit(href, { method: method });
    }
  }
}
